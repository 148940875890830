import type { DateTimeUnit } from "./luxon"
import { DateTime } from "./luxon"

export const ISO8601 = "YYYY-MM-DD[T]HH:mm:ssZZ"

export function parseTime(time: string, date = DateTime.local()): DateTime | undefined {
  if (!time) {
    return undefined
  }

  const [hours, minutes] = time.split(":").map(value => parseInt(value, 10))

  return date.set({ hour: hours, minute: minutes })
}

export function getHours(time: string): number {
  const date = parseTime(time)

  if (!date) {
    return -1
  }

  return date.hour + date.minute / 60
}

export const fromString = (date: string) => DateTime.fromJSDate(new Date(date))

export function parseToStartSunday(dayOfWeek: number) {
  if (dayOfWeek === 1) {
    return 7
  }
  return dayOfWeek - 1
}

export const isSameDay = (dateA: DateTime, dateB: DateTime): boolean => dateA.toISODate() === dateB.toISODate()

export const isToday = (date: DateTime, timezone?: string): boolean => {
  return isSameDay(DateTime.local({ zone: timezone }), date)
}

export const parseDateTime = (
  date?: string | Date | DateTime | null,
  timezone?: string,
  dateTimeFormat?: string,
): DateTime | undefined => {
  if (!date) {
    return undefined
  }

  let dateTime: DateTime
  if (typeof date === "string") {
    const isoDate = date.split(".")[0]
    if (dateTimeFormat) {
      dateTime = DateTime.fromFormat(date, dateTimeFormat)
    } else if (isoDate) {
      dateTime = DateTime.fromISO(isoDate)
    } else {
      return undefined
    }
  } else if (date instanceof Date) {
    dateTime = DateTime.fromJSDate(date)
  } else {
    dateTime = date
  }

  if (timezone) {
    dateTime = dateTime.setZone(timezone)
  }

  return dateTime
}

export function isBetween(date: DateTime, startDate: DateTime, endDate: DateTime) {
  return date > startDate && date < endDate
}

export function isBefore(date: DateTime, dateToCompare: DateTime, unit: DateTimeUnit = "day") {
  return !date.hasSame(dateToCompare, unit) && dateToCompare < date
}

export function isAfter(date: DateTime, dateToCompare: DateTime, unit: DateTimeUnit = "day") {
  return !date.hasSame(dateToCompare, unit) && dateToCompare > date
}

export function getFirstDayOfWeek(date: DateTime, weekStartsOn: 0 | 1 = 0) {
  return weekStartsOn === 0 ? date.startOf("week").minus({ days: 1 }) : date.startOf("week")
}

export function getLastDayOfWeek(date: DateTime, weekStartsOn: 0 | 1 = 0) {
  if (weekStartsOn === 0) {
    const extraWeek = date.get("weekday") === 7 ? 1 : 0

    return date.plus({ weeks: extraWeek }).endOf("week").minus({ days: 1 })
  }

  return date.endOf("week")
}

export function roundToNextHalf(dateTime: DateTime) {
  const newMinute = Math.ceil(dateTime.minute / 30) * 30
  return dateTime.set({ minute: newMinute, second: 0, millisecond: 0 })
}

export function generateDays(startDate: DateTime, days: number) {
  return days > 0 ? Array.from({ length: Math.floor(days) }, (_d, i) => startDate.plus({ days: i })) : []
}

interface PrepareMonthArrayOptions {
  fillWeeks?: boolean
  weekStartsOn?: 0 | 1
}

export function prepareMonthArray(date: DateTime, { fillWeeks = true, weekStartsOn = 0 }: PrepareMonthArrayOptions) {
  const firstDate = date.startOf("month")
  const lastDate = date.endOf("month")
  const daysInMonth = generateDays(firstDate, date.get("daysInMonth"))

  if (fillWeeks) {
    const firstDateInWeek = getFirstDayOfWeek(firstDate, weekStartsOn)

    const lastDateInWeek = getLastDayOfWeek(lastDate, weekStartsOn)

    const daysInFirstWeek = generateDays(firstDateInWeek, firstDate.diff(firstDateInWeek, "days").days)

    const daysInLastWeek = generateDays(lastDate.plus({ days: 1 }), lastDateInWeek.diff(lastDate, "days").days)

    return [...daysInFirstWeek, ...daysInMonth, ...daysInLastWeek]
  }

  return daysInMonth
}

export function getWeekdays(date: DateTime): DateTime[] {
  return Array.from({ length: 7 }, (_, day) => date.plus({ days: day }))
}

export const toISO = (date: string | DateTime = DateTime.local(), timezone?: string) => {
  if (typeof date === "string") {
    const d = parseDateTime(date, timezone)

    if (d === undefined) {
      throw new Error(`Could not parse date ${date} in timezone ${timezone}`)
    }

    date = d
  }

  return `${date.toISO({ includeOffset: false })}Z`
}

/** *
 * Display time without the trailing zeros for 12h time
 * @example
 * toShortTimeFormat(dateTime) // 10am
 * toShortTimeFormat(dateTime) // 10:35am
 * toShortTimeFormat(dateTime) // 17:00
 */
export const toShortTimeFormat = (date: DateTime) => {
  const is12HourFormat = date.toLocaleStringGlobal({ hour: "numeric", minute: "numeric" }).match(/(am|pm)/gi)

  if (!is12HourFormat) {
    return date.toFormat("T")
  }

  if (date.minute === 0) {
    return date.toFormat("ha").toLowerCase()
  }

  return date.toFormat("h:mma").toLowerCase()
}

export const isValidFutureDate = (date: string) => {
  const DATE_TIME_FORMAT = "yyyy-LL-dd'T'HHmm"
  const now = DateTime.local()

  return DateTime.fromFormat(date, DATE_TIME_FORMAT).isValid && DateTime.fromFormat(date, DATE_TIME_FORMAT) > now
}

// Set the hour and minutes of the date
// eg: 20/01/2021, 10.75 => 20/01/2021 10:45
export const composeDateTime = (date: DateTime, newTime: number): DateTime => {
  return date
    .set({
      hour: Math.floor(newTime),
      minute: 0,
    })
    .plus({ minutes: (newTime % 1) * 60 })
}

// Return the first dateTime with the day, month and year of the second
// eg: 20/01/2021 10:45, 10/12/2025 00/00  => 10/12/2025 10:45
export const composeDate = (date: DateTime, newDate: DateTime): DateTime => {
  return date.set({
    day: newDate.day,
    month: newDate.month,
    year: newDate.year,
  })
}
