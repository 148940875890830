/* eslint-disable no-restricted-imports */
import { DateTime, Settings, Info } from "luxon"
import type { DateTimeFormatOptions, DateTimeUnit, DurationLikeObject } from "luxon"

interface WeekSettings {
  firstDay: number
}

interface HoursSettings {
  hour12: boolean
}

/**
 * Extend luxon settings to support week and hours settings
 */
declare module "luxon/src/settings" {
  namespace Settings {
    /**
     * Allows overriding the default locale week settings, i.e. the start of the week, the weekend and
     * how many days are required in the first week of a year.
     * Does not affect existing instances.
     */
    let defaultWeekSettings: WeekSettings

    /**
     * Allows overriding the default locale hours settings, i.e. the 12/24 hour clock.
     * Does not affect existing instances.
     */
    let defaultHoursSettings: HoursSettings
  }
}

// Initialize default hours and week settings
Settings.defaultWeekSettings = {
  firstDay: 1,
}

Settings.defaultHoursSettings = {
  hour12: true,
}

/**
 * Extend luxon to support global locale string formatting
 */
declare module "luxon/src/datetime" {
  export interface DateTime {
    toLocaleStringGlobal: (formatOpts?: DateTimeFormatOptions) => string
  }
}

/**
 * Custom ToLocaleString for Luxon, that uses Settings.defaultLocale for localization instead of
 * local value of this variable.
 * @see https://github.com/moment/luxon/issues/658
 */
DateTime.prototype.toLocaleStringGlobal = function toLocaleStringGlobal(formatOpts?: DateTimeFormatOptions) {
  return this.toLocaleString(
    { ...formatOpts, hour12: Settings.defaultHoursSettings.hour12 },
    { locale: Settings.defaultLocale },
  )
}

export type { DateTimeUnit, DurationLikeObject }
export { DateTime, Info, Settings }
