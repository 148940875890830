// https://worldpopulationreview.com/country-rankings/countries-that-use-12-hour-time
const USES_12H_FORMAT = ["US", "AU", "BD", "CA", "IN", "IE", "MX", "NZ", "PK", "PH", "ZA", "GB"]

/**
 * Check if the locale uses the 12h format
 * @example
 * isUses12hFormat("en-US") // true
 * isUses12hFormat("fr-FR") // false
 */
export const isUses12hFormat = (locale: string) => {
  const localesWith12hFormat = USES_12H_FORMAT.map(locale => "en-" + locale)

  return localesWith12hFormat.includes(locale)
}
